// Import CSS
import styles from '../css/app.pcss';

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import globalEvents from './modules/globals/events.js';
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';
import stickyNav from './modules/globals/sticky-nav.js';


// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});

let proceedArrow = document.querySelector('.proceed-down-arrow');

if( proceedArrow ){
    window.addEventListener('scroll', function(){
        function getYPosition(){
            var top  = window.pageYOffset || document.documentElement.scrollTop
            return Math.ceil(top);
        }

        if(getYPosition() > 50){
            proceedArrow.classList.add('opacity-0');
            proceedArrow.classList.remove('opacity-100');
        }
        else{

            proceedArrow.classList.remove('opacity-0');
            proceedArrow.classList.add('opacity-100');
        }
        // console.log('proceedArrow', proceedArrow, getYPosition() );

    });
}

/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
