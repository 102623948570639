console.log('Sticky Nav');

// Get elements
const hdr = document.getElementById('hdr');
const stickyBar = document.querySelector('.sticky-bar');
const stickyMenuButton = document.querySelector('.sticky_menu');
const closeStickyMenuButton = document.querySelector('.close-sticky-menu');
const mobileStickyContact = document.querySelector('.mobile-sticky-contact');
const topNavbar = document.querySelector('.topnavbar');
const topNavbarModileBurger = topNavbar.querySelector('.hamburger');
const firstLink = topNavbar.querySelector('a');

// Set `positionClass` and `initialBgClass` based on initial state of #hdr
const positionClass = hdr.classList.contains('lg:relative') ? 'lg:relative' : 'lg:absolute';
const initialBgClass = hdr.classList.contains('lg:bg-blue') ? 'lg:bg-blue' : 'lg:bg-transparent';

stickyBar.classList.add('-translate-y-full');
hdr.classList.add(positionClass);

// Function to handle scroll events
function handleScroll() {
    const hdrBottomPosition = hdr.getBoundingClientRect().bottom;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // For screens 1024px or larger, handle the sticky behavior for #hdr and .sticky-bar
    if (window.innerWidth >= 1024) {
        if (hdrBottomPosition <= 0) {
            stickyBar.classList.add('translate-y-0');
            stickyBar.classList.remove('-translate-y-full');
        } else {
            stickyBar.classList.add('-translate-y-full');
            stickyBar.classList.remove('translate-y-0');
        }
    } else {
        // For screens below 1024px, handle mobile-specific behavior
        if (scrollTop > 20) {
            mobileStickyContact.classList.remove('hidden');
            topNavbarModileBurger.classList.add('hamburger-sm');
            topNavbar.classList.add('bg-blue-dark/90');
            topNavbar.classList.replace('py-4', 'py-2');
            firstLink.classList.replace('w-28', 'w-20');
        } else {
            mobileStickyContact.classList.add('hidden');
            topNavbarModileBurger.classList.remove('hamburger-sm');
            topNavbar.classList.remove('bg-blue-dark/90');
            topNavbar.classList.replace('py-2', 'py-4');
            firstLink.classList.replace('w-20', 'w-28');
        }
    }
}

// Function to handle the .sticky_menu button click
function handleStickyMenuClick() {
    hdr.classList.replace(positionClass, 'lg:fixed');
    hdr.classList.replace('translate-y-0', '-translate-y-full');
    hdr.classList.add('sticky-active');
    hdr.classList.replace(initialBgClass, 'lg:bg-blue');

    stickyBar.classList.replace('translate-y-0', '-translate-y-full');
    closeStickyMenuButton.classList.remove('hidden');
}

// Function to handle the .close-sticky-menu button click
function handleCloseStickyMenuClick() {
    hdr.classList.replace('lg:fixed', positionClass);
    if (initialBgClass !== 'lg:bg-blue') {
        hdr.classList.replace('lg:bg-blue', 'lg:bg-transparent');
    }
    hdr.classList.replace('-translate-y-full', 'translate-y-0');
    hdr.classList.remove('sticky-active');

    stickyBar.classList.replace('-translate-y-full', 'translate-y-0');
    closeStickyMenuButton.classList.add('hidden');
}

// Function to apply or remove event listeners based on screen width
function applyStickyNav() {
    if (window.innerWidth >= 1024) {
        window.addEventListener('scroll', handleScroll);
        stickyMenuButton.addEventListener('click', handleStickyMenuClick);
        closeStickyMenuButton.addEventListener('click', handleCloseStickyMenuClick);
    } else {
        window.removeEventListener('scroll', handleScroll);
        stickyMenuButton.removeEventListener('click', handleStickyMenuClick);
        closeStickyMenuButton.removeEventListener('click', handleCloseStickyMenuClick);
        window.addEventListener('scroll', handleScroll);
    }
}

// Initial setup
applyStickyNav();

// Reapply listeners on resize to toggle behavior based on screen size
window.addEventListener('resize', applyStickyNav);
